
.page-remove {
    height: 100vh;


    .config-stack {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .config-title {
        font-weight: 500;
    }

    .config-icon {
        font-size: 48px;
        line-height: normal;
        color: red;
        margin-bottom: 10px;
    }
}